<template>
  <v-dialog v-model="display" max-width="750px">
    <v-card :class="{'pb-3': $vuetify.breakpoint.smAndUp}" height="auto">
      <v-card-text
        v-if="show"
        class="card-height"
        :class="{'text-xs-center': $vuetify.breakpoint.xsOnly}"
        >
        <v-layout row wrap align-center px-3>
          <v-flex xs9>
            <p
              :class="{'font-25': $vuetify.breakpoint.smAndUp, 'font-20 text-xs-left': $vuetify.breakpoint.xsOnly}"
              class="graphik-medium normalcase primary--text mb-0"
            >{{ $ml.get('quoter_buy_with_cash_axa') }}</p>
          </v-flex>

          <v-flex xs3>
            <v-img
              class="ml-auto"
              :src="images.axa"
              :lazy-src="images.axa"
            />
          </v-flex>
        </v-layout>

        <v-layout row wrap align-center px-3>
          <v-flex xs12 sm6 text-xs-center>
            <p
              class="graphik-medium dark-grey--text mb-0"
              :class="{'font-15': $vuetify.breakpoint.smAndUp, 'font-12': $vuetify.breakpoint.xsOnly}"
            >{{ getPaymentText() }}</p>

            <p
              class="graphik-medium dark-grey--text mb-0"
              :class="{'font-25': $vuetify.breakpoint.smAndUp, 'font-17': $vuetify.breakpoint.xsOnly}"
            >{{ quote[policy_type][payment_type].prices.next ? quote[policy_type][payment_type].prices.next : quote[policy_type][payment_type].prices.total | price }}</p>

            <p
              class="graphik-light mb-0" v-if="payment_type !== '1'"
              :class="{'font-15': $vuetify.breakpoint.smAndUp, 'font-10': $vuetify.breakpoint.xsOnly}"
            >{{ $ml.get('quote_result_first_payment') }} {{ quote[policy_type][payment_type].prices.first | price }}</p>
          </v-flex>

          <v-flex xs12 sm6 text-xs-center>
            <template v-if="!g_isLoading && quote_cash.loaded">
              <p
                class="graphik-medium dark-grey--text mb-0"
                :class="{'font-15': $vuetify.breakpoint.smAndUp, 'font-12': $vuetify.breakpoint.xsOnly}"
              >{{ getPaymentText() }}</p>

              <p
                class="graphik-medium dark-grey--text mb-0"
                :class="{'font-25': $vuetify.breakpoint.smAndUp, 'font-17': $vuetify.breakpoint.xsOnly}"
              >{{ quote_cash.prices.next ? quote_cash.prices.next : quote_cash.prices.total | price }}</p>

              <p
                class="mb-0"
                :class="{'font-12': $vuetify.breakpoint.smAndUp, 'font-10': $vuetify.breakpoint.xsOnly}"
                v-if="payment_type !== '1'"
              >{{ $ml.get('quote_result_first_payment') }} <br v-if="$vuetify.breakpoint.xsOnly"> {{ quote_cash.prices.first | price }}</p>
            </template>

            <template v-else>
              <vue-content-loading :width="300" :height="95">
                <rect x="60" y="0" rx="4" ry="4" width="60%" height="15" />

                <rect x="15" y="26" rx="4" ry="4" width="90%" height="25" />

                <rect x="15" y="60" rx="4" ry="4" width="90%" height="10" />
              </vue-content-loading>
            </template>
          </v-flex>
        </v-layout>

        <v-layout row wrap align-end pa-3>
          <v-flex xs12 sm6 text-xs-center px-3>
            <v-btn 
              color="primary"
              dark depressed block
              :loading="g_isLoading"
              class="normalcase graphik-medium mx-0 border-radius-5"
              :class="{'font-11 mb-2': $vuetify.breakpoint.xsOnly, 'mb-3': $vuetify.breakpoint.smAndUp}"
              :disabled="g_isLoading"
              @click.stop="open(quote)"
            >{{ $ml.get('general_buy_card') }}</v-btn>
          </v-flex>

          <v-flex xs12 sm6 text-xs-center px-3>
            <v-btn 
              color="green-cash"
              depressed block
              :loading="g_isLoading || !quote_cash.loaded"
              class="normalcase graphik-medium mx-0 border-radius-5 white--text"
              :class="{'font-11 mb-2': $vuetify.breakpoint.xsOnly, 'mb-3': $vuetify.breakpoint.smAndUp}"
              :disabled="g_isLoading"
              @click.stop="open(quote, true)"
            >{{ $ml.get('general_buy_cash') }}</v-btn>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import VueContentLoading from 'vue-content-loading'

export default {
  name: 'QuoterDialogBuyWithCashAxa',
  components: {
    VueContentLoading
  },
  props: {
    show: Boolean,
    quote: Object,
    payment_type: String,
    policy_type: String
  },
  computed: {
    display: {
      get () {
        return this.show
      },
      set () {
        this.$emit('close')
      }
    },
  },
  data () {
    return {
      quote_cash: {
        loaded: false
      },
      images: {
        axa: require('@/assets/img/companies/axa.png')
      }
    }
  },
  watch: {
    show (v) {
      if (v) {
        this.getQuote()
      }
    }
  },
  methods: {
    getPaymentText () {
      switch (this.payment_type) {
        case '1': return this.$ml.get('quote_result_annual')
        case '2': return this.$ml.get('fields_biannual')
        case '4': return this.$ml.get('fields_quaterly')
        case '12': return this.$ml.get('fields_monthly')
      }
    },
    open (quote, payWithCash) {
      if (payWithCash) {
        this.$goTo('/emitir/' + this.$route.params.type + '/' + this.$route.params.id + '/' + this.quote_cash.product_uuid + '/' + this.quote_cash.frequency + '/' + this.quote_cash.quote_price_uuid + '?cash=true', 'quoter_select_pay_with_cash')
      } else {
        this.$goTo('/emitir/' + this.$route.params.type + '/' + this.$route.params.id + '/' + quote.product_uuid + '/' + quote.frequency + '/' + quote.quote_price_uuid, 'quoter_select_pay_with_creditcard')
      }
    },
    getQuote () {
      this.$api.getCarQuoteByCompany('axa', this.$route.params.id, this.payment_type, this.policy_type, '1',
        response => {
          if (response.body.data) {
            this.quote_cash = {
              ...response.body.data,
              loaded: true
            }
          }
        },
        response => this.$errorHandling(response).then(() => {
          this.getQuote()
        }, () => {
          if (response.body.error === 56) {
            this.error = true
          }
        })
      )
    }
  }
}
</script>

<style scoped>
  .absolute {
    position: absolute !important;
    left: 0 !important;
    bottom: 0 !important;
  }

  .card-height {
    height: calc(100% - 24px) !important;
  }
</style>
